// import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useClientServiceContext } from 'services/clientServiceProvider';
import { setUserProfile } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';

import AgentHelp from 'components/AgentHelp';
import BypassLink from 'components/BypassLink';
import { useStepperProgress } from 'components/ConsumerGuidedExperienceModal/useStepperProgress';
import ContactAgentBanner from 'components/ContactAgentBanner';
import Content from 'components/Content';
import Footer from 'components/Footer';
import GoogleTagManager from 'components/GoogleTagManager';
import Header from 'components/Header';
import PlanTypeModal from 'components/PlanTypeModal';
import { medicarePlanType } from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import { PurlBanner } from 'components/PurlBanner';
import QuickProfileHealthModal from 'components/QuickProfileHealthModal';
import { setDoctorNames } from 'components/YourDoctorModal/slice';
import { setHealthInfoData } from 'components/YourHealthInfoModal/slice';
import {
    setHealthConditionsList,
    setNoHealthCondition
} from 'components/YourHealthIntakeModal/slice';
import { setSelectedMedicaidOption } from 'components/YourMedicaidModal/slice';
import { setPharmacyNames } from 'components/YourPharmacyModal/slice';
import useAuthentication from 'components/hooks/useAuthentication';
import useFetch from 'components/hooks/useFetch';
import useQueryParams from 'components/hooks/useQueryParams';

import bypassContext from 'contexts/bypass';
import hamburgerContext from 'contexts/hamburger';
import phoneContext from 'contexts/phone';
import prescriptionModalContext from 'contexts/prescriptionModal';
import purlAgentContext from 'contexts/purlAgentContext';
import ZipCodeContext from 'contexts/zipCode';

import { setUserData } from 'pages/QuickProfilePersonalInfo/slice';
import { useToDo } from 'pages/TodoItems/useToDo';

import { getProvidersSearchLookUp } from 'utilities/apiSession';
import dataLayer from 'utilities/dataLayer';
import { CONSUMER_BASE_URL } from 'utilities/env';
import getHealthInfoTokenData from 'utilities/getHealthInfoTokenData';
import { isPromise } from 'utilities/isPromise';
import {
    AVAILABLE_AGENTS_ROUTE,
    CONTACT_AN_AGENT_ROUTE,
    FINAL_EXPENSE_AGENT_ROUTE,
    FINAL_EXPENSE_CONTACT_DETAILS_ROUTE,
    MEDICARE_AGENT_ROUTE,
    PROFILE_ROUTE,
    QUICK_PROFILE_ROUTE,
    REQUEST_CALL_BACK,
    VERIFY_EMAIL_ROUTE
} from 'utilities/routes';
import scrollToTop from 'utilities/scrollToTop';
import { PURL_AGENT_CODE } from 'utilities/sessionStorageKeys';
import {
    COUNTIES_KEY,
    COUNTY_FIPS_KEY,
    FINALEXPENSE_AGENT_NPN,
    FINAL_EXPENSE_KEY,
    FROM_EMAIL,
    HEALTH_CONDITION_DETAILS_KEY,
    LEAD_ID_KEY,
    MEDICAID_KEY,
    MEDICARE_AGENT_NPN,
    PHARMACY_LIST_PAGE,
    PLAN_DATE_KEY,
    REDIRECT_PHARMACY,
    STATE_KEY,
    ZIP_CODE_KEY
} from 'utilities/storageKeys';
import { validateZipCode } from 'utilities/validate';

import { updatePurlAgents } from './slice';
import { useHandleQueryParams } from './useHandleQueryParams';
import { usePhone } from './usePhone';
import { usePurl } from './usePurl';

import styles, { mobileWidth } from './styles.module.scss';

import {
    setDrugNames,
    setHasNoPrescriptions
} from 'temp/components/YourPrescriptionModal/slice';
import { countySearch } from 'temp/utilities/apiSearch';
import { getPrescriptions, getPharmacies } from 'temp/utilities/apiSession';
import { getPurlAgentDataByAgentCode } from 'temp/utilities/apiSession/agentPurl';
import { getUserHealthConditions } from 'temp/utilities/apiSession/healthConditions';
import { getPlanCounts } from 'temp/utilities/apiSession/plans';
import { checkModalDataHasChanged } from 'temp/utilities/modalHelper';
import { get, set } from 'temp/utilities/storage';

const MainLayout = () => {
    const location = useLocation();
    const tokenData = getHealthInfoTokenData();

    const { isAuthenticated, user, getAccessTokenSilently, isLoading } =
        useAuth0();

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const [contentId, setContentId] = useState(
        bypassContext._currentValue.contentId
    );

    const [isMobile, setIsMobile] = useState(false);
    const [isHeaderFixed, setIsHeaderFixed] = useState(true);
    const { phone, setPhone } = usePhone();
    const [prescriptionModal, setPrescriptionModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [zipCode, setZipCode] = useState(
        tokenData?.zip_code || get(ZIP_CODE_KEY, '')
    );
    const [counties, setCounties] = useState(get(COUNTIES_KEY, []));
    const [county, setCounty] = useState(
        tokenData?.fips ? tokenData.fips : get(COUNTY_FIPS_KEY, '')
    );
    const [state, setState] = useState(get(STATE_KEY, ''));
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const {
        purlAgentCode,
        purlAgentData,
        fetchPurlAgentDetails,
        setPurlAgentContext,
        setAgentPurlInUrl
    } = usePurl();
    const [error, setError] = useState(false);
    const firstUpdate = useRef(true);
    const queryParams = useQueryParams();
    const { clientService } = useClientServiceContext();
    const [carrierCount, setCarrierCount] = useState(78);
    const [planCount, setPlanCount] = useState(2613);
    const [planDate, setPlanDate] = useState(get(PLAN_DATE_KEY) || '');
    const [onLoadExecuted, setOnLoadExecuted] = useState(false);
    const [updateCounty, setUpdateCounty] = useState(false);

    const initialDrugNames = useSelector(
        state => state.prescriptionDetails.drugNames
    );
    const initialDoctorsNames = useSelector(
        state => state.doctorDetails.doctorNames
    );
    const initialPharmacyNames = useSelector(
        state => state.pharmacyDetails.pharmacyNames
    );
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const { Put: updateAccountData } = useFetch(
        `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Update`
    );
    const { Post: registerUser } = useFetch(
        `${import.meta.env.VITE_APP_CONSUMER_SERVICE_URL}/Lead`,
        false
    );
    const { getData, toDoCreate } = useToDo();

    const history = useHistory();
    useStepperProgress();
    useHandleQueryParams();

    if (
        queryParams.get('error') &&
        queryParams.get('error_description') &&
        queryParams
            .get('error_description')
            .indexOf('Please verify your email address') !== -1
    ) {
        var errorDescription = queryParams.get('error_description');
        var userIdIndex = queryParams
            .get('error_description')
            .indexOf('UserId');
        var emailAddress = errorDescription.substring(
            errorDescription.indexOf('Email') + 7,
            userIdIndex - 1
        );
        var auth0UserId = errorDescription.substring(
            errorDescription.indexOf('UserId') + 8
        );
        localStorage.setItem('verifyEmail', emailAddress);
        localStorage.setItem('verifyEmailUserId', auth0UserId);
        // auth0UserId = auth0UserId.substring(6);
        history.push(VERIFY_EMAIL_ROUTE, {
            email: emailAddress,
            userId: auth0UserId
        });
    }

    const {
        consumerId,
        email,
        firstName,
        hasMedicAid,
        lastName,
        leadIdGuid,
        mbi,
        mbiFirstName,
        mbiLastName,
        partAEffectiveDate,
        partBEffectiveDate,
        phoneNumber,
        userId,
        height,
        weight,
        dateOfBirth,
        isTobaccoUser,
        gender
    } = userProfile;

    const dispatch = useDispatch();

    useAuthentication();

    useEffect(() => {
        if (window.location.pathname === '/contact-an-agent') {
            history.push(CONTACT_AN_AGENT_ROUTE);
        }

        if (window.location.pathname === '/request-call-back') {
            history.push(REQUEST_CALL_BACK);
        }

        if (
            get('isFromProfile') === true &&
            ![
                MEDICARE_AGENT_ROUTE,
                FINAL_EXPENSE_AGENT_ROUTE,
                PROFILE_ROUTE
            ].includes(window.location.pathname)
        ) {
            set('isFromProfile', false);
            dispatch(setSelectedPlanType(medicarePlanType));
        }

        scrollToTop();
    }, [location]);

    useEffect(() => {
        set(FROM_EMAIL, false);
    }, []);

    const getCounties = async zipCode => {
        setButtonDisabled(true);
        setError(false);
        const authToken = await getAuthToken();
        let allCounties = await countySearch({ zipCode, authToken }).catch(
            error => {
                // console.log(error);
            }
        );
        onSetCounties(allCounties);

        if (!allCounties) {
            setError(true);
        }

        if (allCounties?.length) {
            setContinueButtonDisabled(false);
            if (allCounties?.length === 1) {
                setButtonDisabled(false);
                onSetCounty(allCounties[0]?.countyFIPS);
            } else if (allCounties?.length > 1 && county) {
                setButtonDisabled(false);
            } else {
                setCounty('');
                set(COUNTY_FIPS_KEY, '');
            }
        }

        allCounties && onSetState(allCounties[0]?.state);

        return allCounties;
    };

    const updateAccountDataAndSetUserProfile = async payload => {
        const response = await updateAccountData(payload);
        const { leadId, leadIdGuid } = response || {};
        if (response)
            dispatch(
                setUserProfile({
                    ...response,
                    syncId: leadId || leadIdGuid ? response.consumerId : ''
                })
            );
    };

    const onZipCodeChange = async () => {
        set(COUNTY_FIPS_KEY, '');
        setCounty('');
        const payload = {
            consumerId,
            county: county,
            email,
            firstName,
            hasMedicAid,
            inactive: 0,
            lastName,
            leadIdGuid,
            mbi,
            mbiFirstName,
            mbiLastName,
            partAEffectiveDate,
            partBEffectiveDate,
            phoneNumber,
            userId,
            zip: zipCode,
            height,
            weight,
            dateOfBirth,
            isTobaccoUser,
            gender
        };
        if (firstUpdate.current) {
            firstUpdate.current = false;
            if (validateZipCode(zipCode) && county && counties?.length) {
                setButtonDisabled(false);
                setContinueButtonDisabled(false);
                if (
                    isAuthenticated &&
                    consumerId &&
                    window.location.pathname !==
                        FINAL_EXPENSE_CONTACT_DETAILS_ROUTE
                ) {
                    updateAccountDataAndSetUserProfile(payload);
                }
                return;
            }
        }
        if (validateZipCode(zipCode)) {
            const countiesList = await getCounties(zipCode);
            onSetCounties(countiesList);
            let selectedCounty = countiesList && countiesList[0];
            if (
                userProfile.countyFips &&
                countiesList?.length &&
                countiesList
                    .map(c => c.countyFIPS)
                    .includes(userProfile.countyFips)
            ) {
                selectedCounty = countiesList.filter(
                    c => c.countyFIPS === userProfile.countyFips
                )[0];
            }
            if (selectedCounty) {
                setContinueButtonDisabled(false);
                setButtonDisabled(false);
                payload.county = selectedCounty.countyName;
                payload.countyFips = selectedCounty.countyFIPS;
                payload.stateCode = selectedCounty.state;
            }

            if (countiesList && countiesList.length > 1) setUpdateCounty(true);
            if (
                isAuthenticated &&
                consumerId &&
                window.location.pathname !== FINAL_EXPENSE_CONTACT_DETAILS_ROUTE
            ) {
                updateAccountDataAndSetUserProfile(payload);
            }
        } else {
            setContinueButtonDisabled(true);
            setButtonDisabled(true);
            onSetCounties([]);
            set(COUNTY_FIPS_KEY, '');
            setCounty('');
            if (zipCode.length < 5) setError(false);
        }
    };

    const onCountyChange = async () => {
        const payload = {
            consumerId,
            county: county,
            email,
            firstName,
            hasMedicAid,
            inactive: 0,
            lastName,
            leadIdGuid,
            mbi,
            mbiFirstName,
            mbiLastName,
            partAEffectiveDate,
            partBEffectiveDate,
            phoneNumber,
            userId,
            zip: zipCode,
            height,
            weight,
            dateOfBirth,
            isTobaccoUser,
            gender
        };
        const countiesList = await getCounties(
            localStorage.getItem('updatedZipCode') || zipCode
        );
        let selectedCounty;
        if (countiesList && countiesList.length)
            selectedCounty = countiesList.filter(
                c => c.countyFIPS === county
            )[0];
        if (selectedCounty) {
            payload.county = selectedCounty.countyName;
            payload.countyFips = selectedCounty.countyFIPS;
            payload.stateCode = selectedCounty.state;
            if (
                isAuthenticated &&
                consumerId &&
                window.location.pathname !== FINAL_EXPENSE_CONTACT_DETAILS_ROUTE
            ) {
                updateAccountDataAndSetUserProfile(payload);
            }
        }
    };

    const getPlanYear = () => {
        let planYear = 2024;
        if (planDate) {
            planYear = planDate.substring(0, planDate.indexOf('-')) || planYear;
        }
        return planYear;
    };

    const fetchPlanCounts = async () => {
        const authToken = await getAuthToken();
        const result = await getPlanCounts({
            Zip: zipCode,
            Fips: county,
            Year: getPlanYear(),
            authToken
        });
        setCarrierCount(result.carrierCount);
        setPlanCount(result.planCount);
    };

    useEffect(() => {
        onZipCodeChange();
        // this effect should only be rerun when the zip code changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCode]);

    useEffect(() => {
        if (zipCode && county) fetchPlanCounts();
        // this effect should only be rerun when the zip code changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCode, county, planDate]);

    useEffect(() => {
        const countiesList = get(COUNTIES_KEY, []);
        if (updateCounty || (countiesList && countiesList.length > 1)) {
            onCountyChange();
            setUpdateCounty(false);
        }
        // this effect should only be rerun when the zip code changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [county]);

    const setHealthInfoDetails = response => {
        const tobaccoUse = response?.isTobaccoUser
            ? 'Yes'
            : response?.isTobaccoUser === false
            ? 'No'
            : '';
        const dateVal = response?.dateOfBirth
            ? moment(response?.dateOfBirth).format('MM/DD/YYYY')
            : '';

        const feetVal = Math.floor(response?.height / 12);
        const inches = response?.height % 12;

        let payload = {};
        payload.gender = response?.gender;
        payload.heightInFT = feetVal;
        payload.heightIn = inches;
        payload.weight = response?.weight;
        payload.tobaccoUse = tobaccoUse;
        payload.zipCode = zipCode;
        payload.county = county;
        payload.dateOfBirth = dateVal;
        set(FINAL_EXPENSE_KEY, payload);
        dispatch(setHealthInfoData(payload));
    };

    const Onload = useCallback(
        async userProfile => {
            const { consumerid } = userProfile || {};
            dataLayer('Page Load', consumerid);
            let response =
                consumerid &&
                (await clientService
                    .getConsumerByConsumerId(consumerid)
                    .catch(error => {
                        // console.error(error);
                    }));

            const { purl, leadId, leadIdGuid, zip, countyFips, hasMedicAid } =
                response || {};

            if (response?.agentNPN && purl !== get(PURL_AGENT_CODE)) {
                if (purl) {
                    set(PURL_AGENT_CODE, purl);
                    fetchPurlAgentDetails(purl);
                    setAgentPurlInUrl(purl);
                }
            }

            if (response?.consumerAgentAssociations) {
                for (const consumer of response?.consumerAgentAssociations ||
                    []) {
                    consumer?.productType === 'Final Expense' &&
                        set(
                            FINALEXPENSE_AGENT_NPN,
                            consumer?.agentNpnSwitchedTo
                        );

                    consumer?.productType === 'Medicare' &&
                        set(MEDICARE_AGENT_NPN, consumer?.agentNpnSwitchedTo);
                }
            }

            dispatch(
                setUserProfile({
                    ...response
                })
            );
            dispatch(
                setNoHealthCondition(
                    response?.preferences?.hasNoHealthConditions || false
                )
            );
            dispatch(
                setHasNoPrescriptions(
                    response?.preferences?.hasNoPrescriptions || false
                )
            );

            set(LEAD_ID_KEY, leadId || leadIdGuid);
            if (zip) {
                set(ZIP_CODE_KEY, zip);
                setZipCode(zip);
            }
            if (countyFips) {
                set(COUNTY_FIPS_KEY, countyFips);
                setCounty(countyFips);
            }

            if (hasMedicAid === 1) {
                set(MEDICAID_KEY, 'Yes');
                dispatch(setSelectedMedicaidOption('Yes'));
            } else if (hasMedicAid === 0) {
                set(MEDICAID_KEY, 'No');
                dispatch(setSelectedMedicaidOption('No'));
            } else if (hasMedicAid === 2) {
                set(MEDICAID_KEY, 'I’m not sure');
                dispatch(setSelectedMedicaidOption('I’m not sure'));
            }

            if (Boolean(get(REDIRECT_PHARMACY))) {
                dispatch(setUserData({ ...response }));
                history.push({
                    pathname: QUICK_PROFILE_ROUTE,
                    search: `#pharmacy`
                });
            }

            const authToken = await getAuthToken();
            const data = await getProvidersSearchLookUp(authToken);
            const providers = data?.providers ?? [];
            const doctorNames = providers.map(item => {
                const { presentationName, NPI } = item;

                return { presentationName, NPI };
            }, []);

            if (checkModalDataHasChanged(initialDoctorsNames, doctorNames)) {
                dispatch(setDoctorNames(doctorNames || []));
            }

            const conditionsData = await getUserHealthConditions({
                consumerId: userProfile.consumerid,
                authToken
            });

            set(HEALTH_CONDITION_DETAILS_KEY, conditionsData);
            dispatch(setHealthConditionsList(conditionsData));
            setHealthInfoDetails(response);
            const pharmacies = await getPharmacies(authToken);
            const pharmacyNames = pharmacies?.map(item => item.name);

            if (checkModalDataHasChanged(initialPharmacyNames, pharmacyNames)) {
                dispatch(setPharmacyNames(pharmacyNames || []));
            }

            pharmacyNames
                ? set(PHARMACY_LIST_PAGE, true)
                : set(PHARMACY_LIST_PAGE, false);

            const prescriptions = await getPrescriptions(authToken);

            const drugNames = prescriptions.map(item => {
                const { drugName, labelName } = item?.dosage || {};

                return {
                    drugName,
                    labelName
                };
            });

            if (checkModalDataHasChanged(initialDrugNames, drugNames)) {
                dispatch(setDrugNames(drugNames));
            }

            const todoGetUrl = `${CONSUMER_BASE_URL}/ToDo/${consumerid}`;
            const { toDoData } = await getData(todoGetUrl);
            if (
                (toDoData || []).filter(t => t.toDoType?.toDoTypeId === 1)
                    .length === 0
            ) {
                if (
                    (prescriptions &&
                        prescriptions.length === 0 &&
                        !response?.preferences?.hasNoPrescriptions) ||
                    !pharmacies ||
                    pharmacies.length === 0 ||
                    !providers ||
                    providers.length === 0 ||
                    ![0, 1, 2].includes(hasMedicAid)
                ) {
                    await toDoCreate(consumerid, 1);
                }
            }
            if (
                (toDoData || []).filter(t => t.toDoType?.toDoTypeId === 2)
                    .length === 0
            ) {
                if (
                    (conditionsData &&
                        conditionsData.length === 0 &&
                        !response?.preferences?.hasNoHealthConditions) ||
                    !response.gender ||
                    !response.dateOfBirth ||
                    !(
                        response.isTobaccoUser === true ||
                        response.isTobaccoUser === false
                    )
                ) {
                    await toDoCreate(consumerid, 2);
                }
            }
            if (
                (toDoData || []).filter(t => t.toDoType?.toDoTypeId === 3)
                    .length === 0
            ) {
                if (!response.mbi) {
                    await toDoCreate(consumerid, 3);
                }
            }

            const agentAssociations = response?.consumerAgentAssociations || [];
            let purlAgents = [];
            if (agentAssociations.length > 0) {
                purlAgents = await agentAssociations.reduce(
                    async (acc, agentAssociation) => {
                        const purlResponse = await getPurlAgentDataByAgentCode(
                            agentAssociation.agentPurlSwitchedTo
                        );
                        let res = [];
                        if (isPromise(acc)) res = await acc;
                        return [
                            ...res,
                            {
                                ...purlResponse,
                                productType: agentAssociation.productType,
                                associationData: agentAssociation
                            }
                        ];
                    },
                    []
                );
            }
            dispatch(updatePurlAgents(purlAgents));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            dispatch,
            initialDoctorsNames,
            initialDrugNames,
            initialPharmacyNames,
            fetchPurlAgentDetails
        ]
    );

    useEffect(() => {
        if (isAuthenticated && user && !onLoadExecuted && !isLoading) {
            Onload(user);
            setOnLoadExecuted(true);
        } else {
            dataLayer('Page Load', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, Onload, isLoading]);

    useEffect(() => {
        if (zipCode && county) {
            setButtonDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSetCounties = newCounties => {
        set(COUNTIES_KEY, newCounties);
        setCounties(newCounties);
    };

    const onSetCounty = newCounty => {
        set(COUNTY_FIPS_KEY, newCounty);
        setCounty(newCounty);
    };

    const onSetState = newState => {
        set(STATE_KEY, newState);
        setState(newState);
    };

    const onSetZipCode = zip => {
        if (zipCode !== zip) {
            onSetCounties([]);
            onSetCounty('');
            onSetState('');
            setZipCode(zip.replace(/\D/g, ''));
            set(ZIP_CODE_KEY, zip.replace(/\D/g, ''));
        }
    };

    const isAvailableAgentsPage =
        window.location.pathname === AVAILABLE_AGENTS_ROUTE;
    const isPlanDetailPageFromManageAccount =
        queryParams?.get('status') &&
        window.location.pathname.includes('/plan/');
    const isPlanDetailPageFromPolicySnapshot =
        queryParams?.get('from') === 'policySnapshot' &&
        window.location.pathname.includes('customer/enrollmenthistory');
    const isSOAPage = window.location.pathname.includes(
        '/soa-confirmation-form/'
    );
    const isComparePlanFromEmail =
        window.location.pathname.includes('/customer/plans');

    const href = window.location.href;
    useEffect(() => {
        if (
            window.location.pathname !== CONTACT_AN_AGENT_ROUTE &&
            window.location.href.indexOf('?purl=') === -1
        ) {
            localStorage.removeItem('clearFilterSearch');
        }
    }, [href]);

    return (
        <ZipCodeContext.Provider
            value={{
                buttonDisabled,
                carrierCount,
                continueButtonDisabled,
                counties,
                county,
                error,
                getCounties,
                onCountyChange,
                onSetCounties,
                onSetCounty,
                onSetZipCode,
                onZipCodeChange,
                planCount,
                setButtonDisabled,
                setContinueButtonDisabled,
                setCounties: onSetCounties,
                setCounty: onSetCounty,
                setError,
                setPlanDate,
                setState: onSetState,
                setZipCode: onSetZipCode,
                state,
                zipCode
            }}
        >
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <hamburgerContext.Provider
                value={{ isOpen, setIsOpen, isMobile, setIsMobile }}
            >
                <phoneContext.Provider value={{ phone, setPhone }}>
                    <bypassContext.Provider value={{ contentId, setContentId }}>
                        <prescriptionModalContext.Provider
                            value={{
                                prescriptionModal,
                                setPrescriptionModal
                            }}
                        >
                            <purlAgentContext.Provider
                                value={{
                                    purlAgentCode,
                                    purlAgentData,
                                    setPurlAgentContext,
                                    fetchPurlAgentDetails
                                }}
                            >
                                <div className={styles.mainLayout}>
                                    <GoogleTagManager id="GTM-T9PSFB6" />

                                    {window.location.pathname !==
                                        PROFILE_ROUTE && <PurlBanner />}
                                    <BypassLink
                                        contentId={contentId}
                                        headerDisable={() =>
                                            setIsHeaderFixed(false)
                                        }
                                    />
                                    {!purlAgentData && <ContactAgentBanner />}
                                    <Header
                                        shouldHideNavigation={
                                            isAvailableAgentsPage ||
                                            isPlanDetailPageFromManageAccount ||
                                            isPlanDetailPageFromPolicySnapshot ||
                                            isSOAPage ||
                                            isComparePlanFromEmail
                                        }
                                        isHeaderFixed={isHeaderFixed}
                                    />

                                    <Content />
                                    <div className={styles.footerContent}>
                                        <div className={styles.footerContext}>
                                            <Footer
                                                className={styles.footer}
                                                shouldHideLinks={
                                                    isAvailableAgentsPage ||
                                                    isPlanDetailPageFromManageAccount ||
                                                    isPlanDetailPageFromPolicySnapshot ||
                                                    isSOAPage ||
                                                    isComparePlanFromEmail
                                                }
                                            />
                                        </div>
                                    </div>

                                    {!isAvailableAgentsPage && <AgentHelp />}
                                    <PlanTypeModal />
                                    <QuickProfileHealthModal />
                                </div>
                            </purlAgentContext.Provider>
                        </prescriptionModalContext.Provider>
                    </bypassContext.Provider>
                </phoneContext.Provider>
            </hamburgerContext.Provider>
        </ZipCodeContext.Provider>
    );
};

export default MainLayout;
