import PharmacyAddedListItems from 'components/PharmacyAddedListItems';
import PharmacySearch from 'components/PharmacySearch';
import Spinner from 'components/Spinner';

const YourPharmacyModalContent = ({
    defaultValue,
    handleDeletePharmacy,
    handleSelectedPharmacy,
    loading,
    onChange,
    onClear,
    pharmacyDetails,
    pharmacyList,
    pharmacyListPage,
    PER_PAGE,
    isFromQuickProfile = false
}) => (
    <div>
        {pharmacyListPage ? (
            loading ? (
                <Spinner />
            ) : (
                <PharmacyAddedListItems
                    pharmacyList={pharmacyList}
                    handleDeletePharmacy={handleDeletePharmacy}
                    isFromQuickProfile={isFromQuickProfile}
                />
            )
        ) : (
            <PharmacySearch
                defaultValue={defaultValue}
                handleSelectedPharmacy={handleSelectedPharmacy}
                onChange={onChange}
                onClear={onClear}
                pharmacyDetails={pharmacyDetails}
                PER_PAGE={PER_PAGE}
            />
        )}
    </div>
);

export default YourPharmacyModalContent;
